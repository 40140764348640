@import "../styles/variables.scss";

$descritionTabWidth: 450px;
$descritionTabGutter: 15px;

.ParentsPage {
   .page-content {
      padding: 20px;

      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;

      .page-header {
         display: flex;
         align-items: center;

         border-bottom: 2px solid #004F31;
         margin-bottom: 36px;

         @include desktop {
            max-width: 856px;
            margin: 0 auto;
            position: relative;
            left: -27px;
            border-bottom: none;
            margin-bottom: 48px;
         }

         .back-icon {
            background-image: url('../Assets/icons/back.svg');
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            cursor: pointer;
            flex-shrink: 0;

            @include desktop {
               height: 32px;
               width: 32px;
            }
         }

         h1 {
            margin-left: 12px;
            cursor: default;
            font-size: 36px;

            @include desktop {
               margin-left: 24px;
               font-size: 36px;
               border-bottom: 2px solid #004F31;
            }
         }
      }
      .parents-top, .parents-middle, .parents-bottom {
         @include desktop {
            max-width: 800px;
            margin: 0 auto;
         }
      }

      .parents-top {
         margin-bottom: 24px;

         &>h2 {
            text-transform: uppercase;
            font-size: 32px;
            line-height: 36px;
         }

         @include desktop {
            margin-bottom: 48px;
         }
      }
      .parents-middle {
         margin-bottom: 24px;
         .parents-boxes {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            .parents-box-item {
               text-align: center;
               .parents-box-item-image img {
                  max-width: 64px;

                  @include desktop {
                     max-width: 80px;
                  }
               }
               h3 {
                  color: #004F31;
                  font-size: 22px;
                  line-height: 33px;
               }

               @include tablet {
                  flex: 1;
                  padding: 24px;
                  border-radius: 12px;
                  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.125);
               }
            }

            @include tablet {
               flex-direction: row;
               gap: 48px;
            }

            @include desktop {
               margin-top: 64px;
            }
         }

         @include desktop {
            margin-bottom: 64px;
         }
      }
      .parents-bottom {
         .parents-bottom-img {
            max-width: 100%;
            max-height: 300px;
            display: block;
            margin: 48px auto 0 auto;

            @include desktop {
               max-height: 400px;
            }
         }
      }
   }
}