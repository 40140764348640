@import "../../styles/variables.scss";


.loading-slider {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 5px;
    overflow-x: hidden;
    height: 15px;
    z-index: 999;
}

.line {
    position: absolute;
    opacity: 0.4;
    background:map-get($colors, secondaryGreen);
    width: 150%;
    height: 7px;

    @include tablet {
        height: 5px;
    }
}

.subline {
    position: absolute;
    background: map-get($colors, secondaryGreen);
    height: 7px;

    @include tablet {
        height: 5px;
    }
}

.inc {
    animation: increase 2s infinite;
}

.dec {
    animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
    from {
        left: -5%;
        width: 5%;
    }

    to {
        left: 130%;
        width: 100%;
    }
}

@keyframes decrease {
    from {
        left: -80%;
        width: 80%;
    }

    to {
        left: 110%;
        width: 10%;
    }
}
