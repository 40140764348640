@import "../styles/variables.scss";

$descritionTabWidth: 450px;
$descritionTabGutter: 15px;

.ActivitiesPage {
    background:transparent;

    .tab-panel-content {
        margin-top: 40px;
    }

    .activities-tabs {
        margin-top: 70px;
        display: flex;
        justify-content: center;
    }

    .activity-mobile {
        padding: 20px;

    }

    .mobile-tab-header {
        color: map-get($colors, primaryGreen);
        font-size: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            margin-left: 10px;
        }
    }

    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;

    .activities-list {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        justify-content: center;
    }

    .load-more-button-part {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .load-more-button {
            color: map-get($colors, primaryGreen);
            font-size: 20px;
        }
    }

    .links-part {
        display: flex;
        margin-top: 24px;
        padding-bottom: 0px;
        justify-content: center;

        @include desktop {
            justify-content: start;
        }

        .link-tile {
            font-family: 'Nunito';
            display: flex;
            align-items: center;
            padding: 12px 20px;
            border: 1px solid #EDC677;
            border-radius: 7px;
            box-shadow: 0px 0px 1px #c4c4c4;
            z-index: 9;
            transition: all 0.3s ease-out;
            background-color: #fff;

            &:hover {
                background-color: rgba(237, 198, 119, 0.1);
            }

            .link-tile-image {
                width: 18px;
                height: 18px;
                color: #996D14;
                margin-right: 8px;

                &.archive {
                    background-image: url('../Assets/icons/archive.svg');
                }
            }

            .link-tile-label {
                font-size: 16px;
                line-height: 24px;
                color: #996D14;

            }
        }
    }

    .get-involved-tab-mobile {
        padding: 20px;
        overflow-x: scroll;
    }

    .game-rules-tab-mobile {
        padding: 20px;
        overflow-x: scroll;
    }
}