@import "../src/styles/fonts.scss";
@import "../src/styles/variables.scss";

body {
  font-family: $typographyFontFamily, Tahoma, Helvetica, sans-serif;
  font-weight: map-get($typographyFontWeights, Regular);

  a {
    text-decoration: none !important;
  }
}

.DataSheetsApp {
  height: 100vh;
  width: 100%;



  .app-page-content {
    @include desktop {
      min-height: 450px;
      padding-bottom: 64px;
    }
  }
}