@import "../../styles/variables.scss";

$top-menu-height: 80px;

.HeaderModule {
   height: $top-menu-height;

  .header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background: map-get($colors, primaryGreen);
    
    .header-row {
      height: $top-menu-height;
      position: relative;
      background: transparent;

      padding-left: 30px;
      padding-right: 30px;

      align-items: center;
      display: flex;
      justify-content: space-between;

      @include desktop {
        margin: 0 auto;
        max-width: 1100px;
      }

      .header-left-part {
        display: flex;

        .text-part {
          color: map-get($colors, white);

          .foundation-name {
            font-size: 18px;
            font-weight: bold;
          }

          .foundation-sub-name {
            font-size: 12px;
          }

        }
      }

      .logo-breadcrumbs-wrapper {
        display: flex;
        align-items: center;
      }

      .header-text {
        color: map-get($colors, white);
        font-size: 24px;
      }

      .logo-wrapper {
        display: flex;
        position: relative;

        .logo {
          background-image: url('../../Assets/icons/plant.svg');
          background-position: center;
          height: 35px;
          width: 35px;
        }

        span {
          // margin-left: 10px;
        }

        .logo-second-row {
          display: flex;

          div {
            margin-right: 7px;
          }
        }
      }

      // Added new with animated underline
      .header-center-part {
        display: flex;
        gap: 27px;

        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: #fff;
          padding: 5px;

          display: inline-block;
          position: relative;
          text-decoration: none;
          overflow: hidden;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: currentColor;
            transition: width 0.3s ease;
          }

          &.active {
            &::after {
              width: 100%;
            }
          }

          &:not(.active):hover::after {
            width: 100%;
          }
        }
      }

      .header-right-part {
        display: flex;
        align-items: center;

        .header-button {
          font-weight: 600;
          background-color: #CB901A;
          color: #fff;
          font-size: 16px;
          line-height: 24px;
          padding: 12px 20px;
          border-radius: 12px;

          &:hover {
            opacity: 0.9;
          }
        }

        /*
        .facebook-part {
          width: 35px;
          height: 35px;
          background-image: url('../../Assets/icons/facebook_white.svg');
          
        }

        .logo-zahrada {
          width: 30px;
          height: 30px;
          background-image: url('../../Assets/icons/zahrada.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        */

        .header-menu {
          display: flex;
          align-items: center;

          .hamburger-menu-icon {
            margin-left: 20px;

            @include desktop_lg {
              margin-left: 20px;
            }

            .hamburger-menu-icon-item {
              fill: map-get($colors, white);
            }
          }

          .hamburger-menu-close {
            width: 45px;
            height: 45px;
            color: map-get($colors, white);
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 53.7 53.7'><path fill='white' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg>");
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 15px;
            margin-right: -10px;
          }

          .hamburger-menu {
            display: flex;
            flex-direction: column;
            background-color: map-get($colors, white);
            position: absolute;
            top: 80px;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100vh;

            padding: 20px;

            .hamburger-menu-item {
              color: map-get($colors, primaryGreen);
              margin: 10px 0px;
              font-size: 24px;
              display: flex;
              align-items: center;

              span {
                margin-left: 7px;
              }

              &.active {
                color: map-get($colors, secondaryGreen);
              }
            }
          }
        }
      }
    }
  }
}