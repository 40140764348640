@import "../styles/variables.scss";

.ArchivePage {
   max-width: 1110px;
   margin-left: auto;
   margin-right: auto;

   .activities-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 20px;
      justify-content: center;
  }

   .filter-part {
      margin-top: 20px;
      display: flex;
      // flex-direction: column;

      max-width: 980px;
      margin: 20px auto 0 auto;

      .sub-header {
         z-index: 9;
      }

      @include desktop {
         justify-content: end;
      }

      .filter {
         display: flex;
         flex-direction: column;

         padding: 0 15px;

         @include desktop {
            flex-direction: row;
            align-items: center;
         }

         .filter-text {
            width: 100%;

            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            // margin-right: 20px;
            // white-space: nowrap;

            @include desktop {
               width: auto;
               margin-right: 24px;
            }
         }

         .filter-items {
            display: inline-flex;
            z-index: 9;

            label {
               &:last-child {
                  margin-right: 0;
               }
            }
         }
      }
   }
}