@import "../styles/variables.scss";

$descritionTabWidth: 450px;
$descritionTabGutter: 15px;

.ContactsPage {
   .page-content {
      padding: 20px;

      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;

      .page-header {
         display: flex;
         align-items: center;

         @include desktop {
            max-width: 856px;
            margin: 0 auto;
            position: relative;
            left: -27px;
            border-bottom: none;
            margin-bottom: 48px;
         }

         .back-icon {
            background-image: url('../Assets/icons/back.svg');
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            cursor: pointer;
            flex-shrink: 0;

            @include desktop {
               height: 32px;
               width: 32px;
            }
         }

         h1 {
            margin-left: 12px;
            cursor: default;
            font-size: 36px;

            @include desktop {
               margin-left: 24px;
               font-size: 36px;
               border-bottom: 2px solid #004F31;
            }
         }
      }
   }
}