$typographyFontFamily: "Nunito";

$typographyFontSizes: (
    headerLogo: 24px,
    headerLogoMobile: 24px,

    carouselHeader: 28px,
    carouselHeaderMobile: 26px,
    carouselText: 22px,
    carouselTextMobile: 20px,

    voucherText: 22px,

    mainTextHeader:32px,
    mainTextHeaderMobile:30px,

    mainTextSubHeader:28px,
    mainTextSubHeaderMobile: 24px,

    mainTextButton: 16px,
    conditionsHeader: 22px,
    hamburgerMenuItem: 32px
);

$typographyFontWeights: (
    Black: 900,
    Bold: 700,
    SemiBold: 500,
    Regular: 300,
    Light: 100,
    Thin: 90
);

$typographyFontStyles: (
    Italic: italic,
    Normal: normal
);

$typographyFontNames: (
    Black: "Nunito-Black",
    Bold: "Nunito-Bold",
    SemiBold: "Nunito-SemiBold",
    Regular: "Nunito-Regular",
    Light: "Nunito-Light",
    Thin: "Nunito-ExtraLight",
    Italic: "Nunito-Italic"
);

$iconsFontWeights: (
    Bold: 600,
    Light: 100
);

$applicationPadding: 80px;
$applicationContainer: 1600px;

$colors: (
    primaryGreen: #004F31,
    secondaryGreen: #25B036,
    white: white,
    black: black,
    text: #414157
);

$breakpoints: (
    xs: 0,
    s: 576px,
    m: 992px,
    l: 1200px,
    xl: 1400px
);

$container-widths: (
  xs: 575px,
  sm: 991px,
  md: 1199px,
  lg: 1399px,
) !default;

$grid-gutters: (
    xs: 20px,
    sm: 20px,
    md: 30px,
    lg: 30px
);

@mixin tablet {
    @media (min-width: #{map-get($breakpoints, s)}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{map-get($breakpoints, m)}) {
        @content;
    }
}

@mixin desktop_lg {
    @media (min-width: #{map-get($breakpoints, l)}) {
        @content;
    }
}

@mixin desktop_xlg {
    @media (min-width: #{map-get($breakpoints, xl)}) {
        @content;
    }
}

@mixin medium {
    @media (min-width: #{map-get($breakpoints, s)}) and (max-width: #{map-get($breakpoints, l) - 1}) {
        @content;
    }
}

@mixin set-responsive-value($prop, $x576, $x768, $x992, $x1200) {
    #{$prop}: $x576;

    @include tablet {
        #{$prop}: $x768;
    }

    @include desktop {
        #{$prop}: $x992;
    }

    @include desktop_lg {
        #{$prop}: $x1200;
    }
}


