@import "../../styles/variables.scss";

.FooterModule {
    border-top: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    padding: 15px 15px 50px 15px;
    gap: 20px;

    @include desktop {
        padding-bottom: 15px;
    }

    .content {
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        .logos-part {
            display: flex;
            flex-direction: column;

            margin-left: auto;
            margin-right: auto;

            @include desktop {
                flex-direction: row;
                margin-left: initial;
                margin-right: initial;
                align-items: center;
            }

            .footer-logo {
                display: inline-block;
                text-align: center;

                &:hover {
                    opacity: 0.85;
                }
            }

            @include desktop {
                .footer-logo {
                    text-align: left;
                }
            }

            .footer-logo.logo-promeny img {
                // width: 100%;
                height: 90px;
            }

            .footer-logo.logo-usv img {
                // width: 100%;
                height: 60px;
            }
        }

        .links-part {
            display: flex;
            flex-direction: column;

            .links-part-row {
                flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin: 10px 0;
                align-content: center;

                @include desktop {
                    flex-direction: row;
                    justify-content: end;
                    gap: 32px;
                    margin: 5px 0;
                }

                a {
                    flex: 0 0 auto;
                    text-align: center;
                }

                &.links-part-fb a img {
                    height: 48px;
                    width: 48px;

                    @include desktop {
                        height: 24px;
                        width: 24px;
                    }

                    &:hover {
                        opacity: 0.85;
                    }
                }

                &.links-part-green a {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #05652D;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                &.links-part-gold a, &.links-part-gold button {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #CB901A;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-family: 'Nunito', sans-serif;
                    text-decoration: underline !important;

                    &:hover {
                        text-decoration: none !important;
                    }

                    @include desktop {
                        font-size: 14px;
                    }
                }
            }

            // margin-left: auto;
            // margin-right: auto;
            // margin-top: 15px;

            /*
            @include desktop {
                flex-direction: row;
                margin-left: initial;
                margin-right: initial;
                margin-top: 0px;
                gap: 32px;
            }
            */

            /*
            a {
                color: map-get($colors, text);
            }
            */
        }
    }
}