@import "../../styles/variables.scss";

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.loader {
    left: 50%;
    margin-left: -4em;
    font-size: 10px;
    border: .8em solid rgba(218, 219, 223, 1);
    border-left: .8em solid map-get($colors, secondaryGreen);
    animation: spin 1.1s infinite linear;
}

.loader, .loader:after {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: block;
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}