@import "../../styles/variables.scss";

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 0 -10px;

  @include desktop {
    max-width: 820px;
    margin: 0 auto;
  }
}

.gallery-slide {
  padding: 10px;
  width: calc(50% - 20px);
  height: 33vw;
  overflow: hidden;

  @include tablet {
    width: calc(33.33% - 20px);
    height: 25vw;
  }

  @include desktop {
    width: calc(25% - 20px);
    height: 150px;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

