@import "../../styles/variables.scss";

.map-script--wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map-script--heading {
  text-align: center;
}

.map-script--inner {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  line-height: 0;
  width: 100%;

  iframe {
    overflow: hidden;
    width: 100%;
    height: 600px;
  }
}