﻿@import 'variables.scss';

@each $key, $val in $typographyFontWeights {
    @each $styleKey, $style in $typographyFontStyles {
        @font-face {
            font-family: $typographyFontFamily;
            font-weight: $val;
            font-display: swap;
            font-style: $style;
            $fontNameSuffix: '';
            src:    url('../Assets/font/#{map-get($typographyFontNames, $key)}#{$fontNameSuffix}.ttf');
        }
    }
}
