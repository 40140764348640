@import "../../styles/variables.scss";

.rich-text {
  font-size: 16px;
  line-height: 24px;

  max-width: 800px;
  margin: 0 auto;

  h2, h3, h4, h5, h6, ul, ol, blockquote, p {
    margin: 0;
    padding: 0;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 24px;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }

  h5 {
    font-size: 20px;
    line-height: 24px;
  }

  h6 {
    font-size: 16px;
    line-height: 20px;
  }

  ul, ol {
    margin-bottom: 24px;
    padding-left: 24px;
    list-style-position: outside;
  }

  ul li, ol li {
    margin-bottom: 3px;
    list-style-position: outside;
  }

  p {
    margin-bottom: 24px;
  }

  a {
    color: #05652D;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  blockquote {
    margin: 0 0 24px 24px;
    font-size: 24px;
    line-height: 28px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -24px;
      border-left: 4px solid #05652D;
      height: 100%;
    }
  }

  p > img {
    margin: 6px 0;
    width: 100%;
  }

  @include desktop {

  }

  p + h2, ul + h2, ol + h2 {
    margin-top: 36px;
  }

}

.rich-text.narrow {
  p > img {
    @include tablet {
      width: 50%;
    }
    @include desktop {
      width: 33%;
    }
  }
}