@import "../../styles/variables.scss";

[data-component="activity-item"] {
    display: flex;
    height: 280px;
    width: 312px;
    border-radius: 7px;
    box-shadow: 0px 0px 1px rgba(0,0,0,0.25);
    position: relative;
    flex-direction: column;
    background: #FFFBF3;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;

    @include desktop {
        width: calc((100% - 40px)/3);
    }

    .activity-name {
        color: map-get($colors, primaryGreen);
        font-size: 22px;
        margin: 20px;
        font-weight: bolder;
    }

    .tags {
        position: absolute;
        left: 15px;
        bottom: 12px;
        display: flex;

        .activity-tag {
            display: flex;
            align-items: center;
            border-radius: 12px;
            border-width: 2px;
            border-style: solid;
            background: white;
            padding: 0px 10px;
            font-weight: bolder;
            margin-right: 8px;

            &.activity-archive-tag {
                color: map-get($colors, text);
                border-color: map-get($colors, text);
                margin-left: 10px;

                .activity-archive-icon {
                    height: 15px;
                    width: 15px;
                    background-image: url('../../Assets/icons/archive.svg');
                }

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    .activity-image {
        flex: 1;
        overflow: hidden;

        img {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 100%;
            width: 100%;
            transition: transform 0.25s ease-out;
        }
    }

    &:hover {
        .activity-image {
            img {
                @include desktop {
                    transform: scale(1.1);
                }
            }
        }
    }
}