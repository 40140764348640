@import "../styles/variables.scss";

$descritionTabWidth: 450px;
$descritionTabGutter: 15px;
$carouselArrowSize: 17px;

.ActivityPage {
   .page-content {
      padding: 20px;

      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;

      .page-header {
         display: flex;
         align-items: center;

         @include desktop {
            max-width: 856px;
            margin: 0 auto;
            position: relative;
            left: -27px;
         }

         .back-icon {
            background-image: url('../Assets/icons/back.svg');
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            cursor: pointer;
            flex-shrink: 0;

            @include desktop {
               height: 32px;
               width: 32px;
            }
         }

         h1 {
            margin-left: 12px;
            cursor: default;
            font-size: 24px;

            @include desktop {
               margin-left: 24px;
               font-size: 36px;
            }
         }
      }

      .activity-tags {
         margin-bottom: 24px;

         @include desktop {
            max-width: 800px;
            margin: 0 auto 24px auto;
         }
      }

      .activity-tag {
         border-radius: 18px;
         border-width: 2px;
         border-style: solid;
         background: white;
         padding: 2px 10px;
         margin-right: 8px;

         &:first-child {
            margin-left: 36px;
         }

         @include desktop {
            padding: 5px 15px;
            // margin-left: 0;

            &:first-child {
               margin-left: 0;
            }
         }
      }

      .activity-content-wrapper {
         margin-top: 32px;
      }

      .carousel-images-part {
         margin-top: 32px;
         width: 90%;
         margin-left: auto;
         margin-right: auto;

         .carousel .control-arrow,
         .carousel.carousel-slider .control-arrow {
            opacity: 0.8;
         }

         .carousel .control-next.control-arrow:before {
            border-left: $carouselArrowSize solid #fff;
            border-top: $carouselArrowSize solid transparent;
            border-bottom: $carouselArrowSize solid transparent;
         }

         .carousel .control-prev.control-arrow:before {
            border-right: $carouselArrowSize solid #fff;
            border-top: $carouselArrowSize solid transparent;
            border-bottom: $carouselArrowSize solid transparent;
         }
      }

      .worksheet-pdf-part {
         max-width: 800px;
         margin: 32px auto 0 auto;

         .worksheet-item-header {
            font-weight: bold;
            margin-bottom: 6px;
         }

         .worksheet-item-link {
            // margin-right: 10px;
            margin-bottom: 6px;
         }

         .worksheet-item {
            background: map-get($colors, primaryGreen);
            border: 1px solid map-get($colors, primaryGreen);
            border-radius: 5px;

            padding: 2px 10px;
            color: map-get($colors, white);

            display: inline-flex;
            align-items: center;

            margin-right: 10px;
            margin-bottom: 10px;

            .worksheet-item-file-icon {
               background-image: url('../Assets/icons/archive_white.svg');
               height: 18px;
               width: 18px;
               margin-left: 5px;
            }
         }
      }
   }
}